.photo-page {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    &-nav {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;

        h2 {
            color: white;
            text-align: center;
            width: 100%;
        }

        .nav-icon {
            padding: .5rem;
            cursor: pointer;
            transition: all .3s ease-in-out;
            transition-property: opacity;

            &:hover {
                opacity: .8;
            }
        }
    }

    .image-navigation {
        display: flex;
        justify-content: space-between;
        width: 100%;
        .icon-container {
            display: flex;
            flex-direction: column;
            justify-content: center;

            svg {
                cursor: pointer;
                transition: all .3s ease-in-out;
                transition-property: opacity;

                &:hover {
                    opacity: .8;
                }
            }
        }
        .image-holder {
            img {
                user-select: none;
            }
        }
    }

    button {
        padding: .7rem;
        margin: 1rem 0;
        border-radius: 18px;
        font-size: 1rem;
        width: 20rem;
        border: solid 1.3px grey;

        &:hover {
            cursor: pointer;
        }
    }

    .share-button {
        background-color: #004171;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: .4rem;

        &:hover {
            background-color: lighten($color: #004171, $amount: 20%);
        }
    }

    .added-to-cart {
        background-color: rgb(236, 236, 236);
        color: black;
        border: solid;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            cursor: default;
        }
    }

    .download {
        background-color: #004171;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: .4rem;

        &:hover {
            background-color: lighten($color: #004171, $amount: 20%);
        }
    }
}

@media (max-width: 1000px) {
    .photo-page {
        .image-navigation {
            justify-content: center;
            svg {
                display: none;
            }
        }
    }
}

@media (max-width: 700px) {
    .photo-page {
        .image-navigation {
            span {
                img {
                    width: 100%;
                }
            }
        }
    }
}