@import "../Grid.scss";

.albums-not-available {
    display: flex;
    align-items: center;
    color: white;
    flex-direction: column;
    width: 100%;

    a {
        color: white;
        font-size: 1.2rem;
    }
}