.search-drop-down {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #28335A;
    height: min-content;
    overflow-y: scroll;
    border-radius: 8px;
    text-align: center;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        width: 100%;
    }

    li {
        cursor: pointer;
        width: 100%;
        transition-duration: .5s;
        transition-property: background-color;
        border-radius: 3px;
        padding: 3px;

        &:hover {
            background-color: darken($color: #28335A, $amount: 10%);
        }
    }
}