@import "../Grid.scss";

.list-container {
    margin-left: .5rem;
}

@media (max-width: 950px) {
    .list-container {
        margin-left: 0;
    }
}