.item {
    box-shadow: 6px 6px 2px 1px rgba(0, 0, 0, .3);
    display: flex;
    align-items: center;
    padding: 2rem;
    height: 8rem;
    background-color: #074e81;
    margin-top: 1.3rem;
    width: 100%;

    span {
        margin: 0 2.5rem;
        height: 198%;
        img {
            width: 100%;
            height: 100%;
        }
    }

    .cross-icon {
        width: 1.8rem;
        height: 1.8rem;
    }

    .cross-icon:hover {
        cursor: pointer;
    }
}

@media (max-width: 500px) {
    .item {
        h3 {
            display: none;
        }
    }
}