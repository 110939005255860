.header-item {
    display: flex;
    height: 100%;
    justify-content: space-between;
    gap: .7rem;
    justify-content: center;
    
    a {
        height: 100%;
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        color: white;
    }
}