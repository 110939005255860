@import "../../config";

.search-title {
    margin-top: 4rem;
    margin-bottom: 0;
    text-align: center;
    font-weight: 300;
    color: white;
    width: 100%;
}

// .photos-container {
//     margin-top: 2rem;
//     margin-left: 1rem;

//     width: 100%;
//     display: grid;
//     // column-gap: $grid-gap;
//     grid-template-columns: $grid-width $grid-width $grid-width $grid-width;
//     justify-content: center;
//     // place-items: center;

//     h1 {
//         color: white;
//         text-align: left;
//         width: 90%;
//     }
// }

.photos-container {
    margin: 0 auto;
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
}

@media (max-width: 900px) {
    .photos-container {
        width: 100%;
        margin: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0;

        div {
            margin: .8rem 0;
            width: 100%;
        }
    }
}

.news-btn-container {
    display: flex;
    width: 100%;
    justify-content: center;
    
    .news-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: .8rem;
        border-radius: 18px;
        border: solid 2px white;
        background-color: rgba(0, 0, 0, 0.5);
        color: white;
        transition: all .3s;
        transition-property: color, background-color;

        svg {
            transition: all .3s;
            transition-property: fill;
        }

        span {
            padding: 0 .5rem;
            font-family: $font;
            font-size: 1.2rem;
        }

        &:hover {
            background-color: white;
            color: black;

            svg {
                fill: black;
            }
        }
    }
}

.discount-btn {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: .7rem;
    border-radius: 18px;
    border: solid 2px gold;
    background-color: rgba(0, 0, 0, 0.5);
    transition: all .3s;
    transition-property: color, background-color;
    font-family: "Poppins";

    span {
        color: white;
        font-size: 13px;
    }

    h2 {
        margin: 0;
        color: gold;
    }

    .flashing-gif {
        width: 7rem;
    }

    svg {
        transition: all .3s;
        transition-property: fill;
    }

    &:hover {
        background-color: white;

        span, h2 {
            color: black;
        }

        svg {
            fill: black;
        }
    }
}

.sub-title {
    color: white;
    font-weight: 350;
    text-align: center;
    width: 100%;
    margin: 1rem;
}

.highlighted {
    color: white;
    font-weight: bolder;
}

.share-icons {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.share-icon {
    &:hover {
        cursor: pointer;
    }
}