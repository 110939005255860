.incorrect {
    background-color: #151C36;
}

.correct {
    background-color: #147549;
}

.close {
    background-color: #9D5C3B;
}