.info-page {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .text-container {
        width: 95%;
        margin-top: 1rem;
        padding: .7rem;
        border: 1.5px solid #F0F0F0;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom: none;

        #stripe-link {
            text-decoration: none;

            img {
                margin-bottom: 1rem;
            }
        }

        h1, h2, h3, h4, h5, h6, p, ol, a, span {
            color: #f0f0f0;
            margin: .3rem;
        }

        h1 {
            font-size: 2.3rem;
            margin-bottom: .8rem;
            margin-top: 0;
        }

        h2 {
            font-size: 1.9rem;
        }

        p {
            font-weight: 300;
        }

        b {
            font-weight: bolder;
        }

        ol {
            list-style-type: decimal;
        }

        li, p {
            font-size: 1.3rem;
        }

        .gif {
            margin-top: .8rem;
            margin-left: 0;
            padding: 0;
        }

        li {
            padding: auto;
            p {
                font-weight: 300;
                display: flex;
                flex-wrap: wrap;
                align-items: center;

                .mobile-btn {
                    display: none;
                }

                .download-btn {
                    width: 12rem;
                    height: 2rem;
                    margin-left: .5rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    &:hover {
                        background-color: #004171;
                    }
                }

                .spanicon {
                    margin: 0 .4rem;
                }

                .checkout-btn {
                    padding: .3rem;
                    margin: 0 .5rem;
                    border-radius: 18px;
                    font-size: 1rem;
                    text-align: center;
                    width: 12rem;
                    border: solid;
                    background-color: #0F0F0F;
                    color: white;
                }

                .add-to-cart {
                    width: 12rem;
                    height: 2rem;
                    margin-left: .5rem;
                    font-size: small;

                    &:hover {
                        background-color: #004171;
                    }
                }
            }
            ::marker {
                font-weight: bolder;
            }
        }
    }
}

@media (max-width: 750px) {
    .info-page .text-container {
        li {
            p {
                .mobile-btn {
                    display: block;
                }
                .add-to-cart, .checkout-btn, .download-btn {
                    display: none;
                }
            }
        }
    }
}

@media (max-width: 700px) {
    .info-page .text-container {
        iframe {
            width: 100%;
        }
    }
}

@media (max-width: 540px) {
    .info-page .text-container {
        .gif {
            width: 100%;
        }
    }
}