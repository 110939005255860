.discount-popup {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40rem;
    height: 70%;
    z-index: 2;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #0F0F0F;
    color: #F0F0F0;
    border-radius: 14px;
    padding: .5rem;
    border: 2px solid gold;

    main {
        display: flex;
        flex-direction: column;
        align-items: center;

        h2, h3 {
            margin: .3rem;
        }

        ul {
            list-style: none;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0;

            li {
                display: flex;
                align-items: center;

                span {
                    font-size: 2rem;
                }

                img {
                    width: 5rem;
                }
            }
        }
    }

    h1 {
        font-size: 2rem;
        margin: 0;
        margin-top: 30px;
        margin-left: .3rem;
    }

    .top {
        display: flex;
        width: 100%;
        justify-content: space-between;
        
        svg {
            cursor: pointer;
        }

        .center {
            display: flex;
            align-items: center;
            margin-bottom: 1rem;
        }
    }

    .flashing-gif {
        width: 7rem;
    }
}

@media (max-width: 650px) {
    .discount-popup {
        width: 100%;

        h1 {
            font-size: 1.7rem;
        }

        .flashing-gif {
            width: 6rem;
        }

        h2 {
            font-size: 1.25rem;
        }
        main {
            ul {
                li {
                    span {
                        font-size: 1.4rem;
                    }
                }
            }
        }
    }
}

@media (max-width: 500px) {
    .discount-popup {
        .flashing-gif {
            width: 5rem;
        }

        h1 {
            font-size: 1.6rem;
        }

        h3 {
            font-size: .8rem;
        }
    }
}

@media (max-width: 350px) {
    .discount-popup {
        .flashing-gif {
            width: 4rem;
        }

        h1 {
            margin-top: 20px;
            font-size: 1.1rem;
        }

        h2 {
            font-size: 1.1rem;
        }
    }
}

@media (max-height: 800px) {
    .discount-popup {
        height: 90%;
    }
}

@media (max-height: 615px) {
    .discount-popup {
        height: 98%;
    }
}