.add-to-cart {
    background-color: #004171;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: lighten($color: #004171, $amount: 20%);
    }
}

.add-to-cart, .added-to-cart {
    gap: .4rem;
    padding: 1rem;
    border-radius: 18px;
    font-size: 1rem;
    width: 20rem;
    border: solid 1.3px grey;
}

.added-to-cart {
    background-color: rgb(236, 236, 236);
    color: black;
    border: solid;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        cursor: default;
    }
}