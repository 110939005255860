.cart {
    width: 100%;

    h2 {
        color: white;
        text-align: center;
    }
    
    .cart-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .total {
            background-color: #073f68;
            color: white;
            text-align: center;
            font-size: 2rem;
            margin: 1.5rem 0;
            width: 100%;
            height: 3.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        button {
            padding: .7rem;
            margin: 1.4rem 0;
            border-radius: 18px;
            font-size: 1rem;
            width: 20rem;
            border: solid;
            background-color: #0F0F0F;
            color: white;
        
            &:hover {
                cursor: pointer;
                background-color: lighten($color: #0F0F0F, $amount: 20%);
            }
        }
    }
}