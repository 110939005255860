.card-container {
    position: relative;
    text-align: center;
    text-decoration: none;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    &:hover {
        cursor: pointer;
    }

    h4 {
        font-size: 1.6rem;
        color: white;
        width: 100%;
        margin: 0;
        color: #F2F2F2;
        user-select: none;
    }
    
    span {
        display: flex;
        width: 100%;
        justify-content: center;
        transition: opacity .2s ease-out;

        &:hover {
            opacity: .9;
        }

        img {
            width: 100%;
        }
    }
}