@import "../config";

.page-content {
    margin-top: $header-height;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;
    overflow-x: hidden;

    .obfuscator {
        background-color: rgba(0, 0, 0, .5);
        width: 100%;
        height: 100%;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 2;
    }

    .visible {
        display: block;
    }
    .invisible {
        display: none;
    }
}

@media (max-width: 1100px) {
    .page-content {
        margin-left: 0;
        width: 100%;
    }
}