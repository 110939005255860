@import "../../components/Grid.scss";

.success-content {
    width: 100%;

    h1, h2, a {
        color: white;
        margin-left: .5rem;
        width: 100%;
        text-align: center;
    }
    .download-all {
        display: flex;
        flex-direction: column;
        align-items: center;
        &-btn {
            padding: .7rem;
            margin: 1.4rem 0;
            border-radius: 18px;
            font-size: 1rem;
            border: solid 1.3px grey;
            background-color: #004171;
            text-align: center;
            color: white;

            &:hover {
                cursor: pointer;
                background-color: lighten($color: #004171, $amount: 20%);
            }
        }
    }
}