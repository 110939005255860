@import "../../config";

.search-page-container {
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 1rem;
    align-items: center;
    margin-bottom: 2.5rem;

    .sub-title {
        color: white;
        font-weight: 300;
    }

    .search-submit {
        height: 4rem;
        width: 10%;
        padding: 0;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border: solid 3px white;
        border-radius: 15px;
        font-family: $font;
        font-size: 20px;
        background-color: black;
        color: white;
        transition: all .2s;
        transition-property: background-color, color;

        &:hover {
            color: black;
            background-color: white;
        }

        svg {
            transition: all .08s ease-in-out;
            transition-property: color;

            &:hover {
                color: black;
            }
        }
    }

    .search-bar-container {
        width: 100%;
        margin: 0;
        gap: 0;
    }
}

@media (max-width: 600px) {
    .search-page-container {
        .search-submit {
            width: 5rem;
            span {
                display: none;
            }
        }
    }
}