@import "../../config";

.register-button {
    padding: .5rem;
    margin: 1.4rem 0;
    border-radius: 18px;
    font-size: 1rem;
    width: 7rem;
    border: solid;
    background-color: #0F0F0F;
    color: white;
    font-family: "Poppins", sans-serif;

    &:hover {
        cursor: pointer;
        background-color: lighten($color: #0F0F0F, $amount: 20%);
    }
}

.donate-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .5rem;
    margin: 1.4rem .5rem;
    border-radius: 18px;
    font-size: 1rem;
    width: 7rem;
    gap: 2px;
    border: solid;
    background-color: #272727;
    color: white;
    font-family: "Poppins", sans-serif;

    &:hover {
        cursor: pointer;
        background-color: lighten($color: #272727, $amount: 10%)
    }
}

.header {
    background-color: #004171;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    z-index: 2;
    position: fixed;
    top: 0;

    @media (max-width: 450px) {
        .donate-nav-button {
            display: none;
        }

        .donate-button {
            margin-right: .4rem;
        }
    }

    .desktop-left {
        display: flex;
        align-items: center;
        gap: 1rem;
        height: 100%;

        .header-items-list {
            display: flex;
            gap: 1rem;
            height: 100%;
        }
    }

    &-site-logo {
        display: flex;
        align-items: center;
        &-image {
            user-select: none;
            border-radius: 18px;
            width: 70px;
            height: 54px;
        }
    }

    * {
        margin-right: .3rem;
    }

    .cart-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        padding: 0;

            
        .profile-picture {
            width: 2.2rem;
            margin-left: .6rem;
            border-radius: 18px;
        }

        .saved-photos-icon-container {
            display: flex;
            height: 100%;
            align-items: center;
            margin-right: 1rem;
            a {
                display: flex;
                height: 100%;
                align-items: center;
            }
            display: none;
        }

        a {
            div {
                margin: 0;
            }
            margin: 0;
            .cart-icon {
                margin: 0;
            }
        }

        .cart-count {
            margin-bottom: .08rem;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            background-color: black;
            padding: 0 .1rem;
            width: 22px;
            height: 20px;
            border-radius: 10px;
        }
    }

    .icon {
        transition: change-color .4s;
        color: white;
        &:hover {
            cursor: pointer;
        }
    }

    .search-icon-container {
        display: flex;
        align-items: center;
        margin: 0 1rem;
        border-radius: 18px;
        padding: .1rem;
        height: 2.6rem;

        .search-icon {
            color: white;
        }
    }

    .bars {
        margin-left: .5rem;
        display: none;
    }
}

.mobile {
    margin-left: 0;
    justify-content: space-between;
}

@keyframes change-color {
    to {
        color: #458BA9;
    }
}

@media (max-width: 900px) {
    .header {
        .desktop-left {
            display: none;
        }

        .bars {
            display: block;
        }

        .cart-container {
            .saved-photos-icon-container {
                display: block;
            }
        }
    }
}

@media (max-width: 650px) {
    .header {
        .cart-container {
            .search-container {
                padding: 0;
                background-color: #004171;
                margin-right: 1rem;

                p {
                    display: none;
                }

                .search-icon {
                    color: white;
                }
            }
        }
    }
}