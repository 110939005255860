.about-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5;
    padding: 0;
    margin: 0;
    margin-bottom: 2.5rem;

    a {
        color: rgb(36, 34, 34);
    }

    &-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;      
        
        p {
            margin-top: 0;
            font-size: 1.5rem;
        }

        img {
            width: 40%;
            height: auto;
            margin-bottom: .8rem;
            border-radius: 12px;
            cursor: pointer;
            transition: all .3s opacity;
            &:hover {
                opacity: .8;
            }
        }
    }
}

@media (max-width: 1000px) {
    .about-container {
        &-content {
            p {
                font-size: 1.3rem;
            }
            img {
                width: 80%;
            }
        }
    }
}

@media (max-width: 850px) {
    .about-container {
        &-content {
            p {
                font-size: 1.2rem;
            }
            img {
                width: 98%;
            }
        }
    }
}

@media (max-width: 500px) {
    .about-container {
        &-content {
            p {
                font-size: 1rem;
            }
            img {
                width: 98%;
            }
        }
    }
}