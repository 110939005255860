@import "../../config";

.exiting {
    animation-name: slideOut;
    animation-duration: .6s;
}

.nav {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
    height: 100%;
    max-height: 100%;
    width: 17rem;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    overflow-y: auto;
    animation-name: slideIn;
    animation-duration: .6s;

    h1 {
        font-size: 2rem;
        user-select: none;
    }

    .download-app {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin: 1.5rem 0;

        span {
            font-size: 1.3rem;
        }

        a {
            width: 100%;
            text-align: center;

            img {
                width: 65%;
            }
        }
    }

    footer {
        justify-self: end;
    }

    .list {
        display: flex;
        list-style: none;
        list-style-type: none;
        flex-direction: column;
        align-items: center;
        width: $nav-width;
        margin: 0;
        padding: 0;
    }
}

@media (max-width: 850px) {
    .nav {
        h1 {
            font-size: 1.8rem;
        }
        .list {
            .visible-list {
                display: flex;
                flex-direction: column;
                position: absolute;
                top: 0;
                left: $nav-width;
                width: 100%;
                align-items: center;
                padding-bottom: 2rem;
            }

            .unvisible-list {
                display: none;
            }

            .bars {
                display: block;
                margin-right: 2.5rem;
            }

            .cart-link {
                display: block;
            }

            .cart-icon, .cart-count {
                display: none;
            }
        }
    }
}

@keyframes slideIn {
    from {
        left: -17rem;
    }
    to {
        left: 0;
    }
}

@keyframes slideOut {
    from {
        left: 0;
    }
    to {
        left: -17rem;
    }
}