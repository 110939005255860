.tutorials-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
    align-items: center;

    .tutorial {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 1rem;
    }

    h2 {
        color: #F0F0F0;
        margin: 0.3rem;
        text-align: center;
    }
}