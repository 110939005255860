.image-container {
    box-shadow: 6px 6px 2px 1px rgba(0, 0, 0, .3);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding-bottom: 1rem;
    border: solid 2px white;

    h4 {
        font-size: 1.8rem;
    }

    p {
        font-size: 1.2rem;
        text-align: center;
    }

    img {
        width: 100%;
        padding: .8rem;
    }

    button {
        padding: .7rem;
        margin: 1.4rem 0;
        border-radius: 18px;
        font-size: 1rem;
        width: 75%;
        border: solid 1.3px grey;

        &:hover {
            cursor: pointer;
        }
    }
}

.download-btn {
    padding: .7rem;
    margin: 1.4rem 0;
    border-radius: 18px;
    font-size: 1rem;
    width: 75%;
    border: solid 1.3px grey;

    background-color: #004171;
    color: white;

    &:hover {
        background-color: lighten($color: #004171, $amount: 20%);
    }
}